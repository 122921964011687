import { StringifyFunc } from "@appiodev/xcore-client/xcore-ui";
import { File } from "@appiodev/xcore-core";
import { AspectRatio, Box, BoxProps, Button, Icon, Img } from "@xcorejs/ui";
import ArrowIcon from "components/icons/32/arrow.svg";
import HeroImage from "design-system/robe/Carousel/HeroImage";
import { Dispatch, FC, MutableRefObject, RefObject, SetStateAction, memo, useEffect, useRef, useState } from "react";
import ImageGallery from "react-image-gallery";
import ShortVideo, { ShortVideoValuesRestructured } from "templates/products/ProductDetail/ShortVideo";
import { useLayout } from "xcore";
import { ShortVideo as ShortVideoType, Slide, SlideValues, Video } from "xcore/types";

import { LG, MD, SM, XS } from "../constants/file-resolution-breakpoints";
import VideoComponent from "./Video";
import { createGlobalStyle } from "styled-components";

export type SlideType = Video | ShortVideoType | File | Slide;

type ShortVideoSizes = 0 | 1 | 2 | 3 | 4 | 5; // serves purpose of picking either shortVideo0, shortVideo1,... shortVideo5 based on its resolution

/**
 * @property {(Video | ShortVideoType | File | Slide)[]} [slides] - Slides of the carousel
 * @property {number} [aspectRatio] - Aspect ratio to be applied to the slides
 * @property {boolean} [showFullscreenButton] - Whether full screen button should be shown, allowing to go into full screen mode
 * @property {boolean} [autoPlay] - Whether the carousel should change slides automatically
 * @property {number} [autoPlaySpeed] - Specifies duration between slides in autoplay mode
 * @property {boolean} [showBullets] - Whether to show bullets in the bottom part of the slide allowing to slide further than one slide to the sides
 * @property {boolean} [showNavArrows] - Whether to show navigation arrows on the sides of the carousel
 * @property {number} [startIndex] - Specifies the starting position of the carousel
 * @property {boolean} [waitForWindowObjectBeforeRender] - Whether to wait for JS window object before rendering. In some use cases it is firstly needed to resolve correct video/image ratio based on user width before rendering the component
 * @property {number} [startIndex] - Specifies the starting position of the carousel
 * @property {boolean} [forceFullScreen] - Whether to set fullscreen mode immediately with the first render without any user action
 * @property {Dispatch<SetStateAction<boolean>>} [setRenderCarousel] - If rendering the component is based on user action (such as click somewhere) this allows to hide the carousel again
 * @property {MutableRefObject<HTMLElement>} [refToScrollIntoAfterFullScreenClose] - When leaving full screen mode, the view might scroll to the very bottom of the page. By specifying this ref it is possible to scroll into specific element on the page after leaving the full screen mode
 */
type Props = {
  slides: (Video | ShortVideoType | File | Slide)[];
  aspectRatio?: number;
  showFullscreenButton?: boolean;
  autoPlay?: boolean;
  autoPlaySpeed?: number;
  showBullets?: boolean;
  showNavArrows?: boolean;
  startIndex?: number;
  waitForWindowObjectBeforeRender?: boolean;
  forceFullScreen?: boolean;
  setRenderCarousel?: Dispatch<SetStateAction<boolean>>;
  refToScrollIntoAfterFullScreenClose?: MutableRefObject<HTMLDivElement | null>;
} & BoxProps;

// Define ImageGalleryRef type
type ImageGalleryRef = {
  fullScreen: () => void;
  slideToIndex: (index: number) => void;
};

// Constants
const SLIDE_DURATION = 550;

export const VideoImageCarousel: FC<Props> = ({
  slides,
  aspectRatio,
  showFullscreenButton,
  autoPlay,
  autoPlaySpeed,
  showBullets,
  showNavArrows,
  startIndex = 0,
  waitForWindowObjectBeforeRender,
  forceFullScreen,
  setRenderCarousel,
  refToScrollIntoAfterFullScreenClose,
  ...props
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(startIndex);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [slideDuration, setSlideDuration] = useState(SLIDE_DURATION);
  const galleryRef = useRef<ImageGalleryRef>(null);

  const [width, setWidth] = useState<number | null>(null);
  const isReadyToRender = waitForWindowObjectBeforeRender ? width !== null : true;

  // window size handling
  useEffect(() => {
    if (typeof window !== "undefined") {
      const updateWidth = () => {
        setWidth(window.innerWidth ?? 0);
      };

      updateWidth();
      window.addEventListener("resize", updateWidth);

      return () => {
        window.removeEventListener("resize", updateWidth);
      };
    }
  }, []);

  // fullscreen handler
  useEffect(() => {
    forceFullScreen && galleryRef.current?.fullScreen?.();
  }, [forceFullScreen]);

  useEffect(() => {
    if (!showFullscreenButton) return;
    const toggleFullscreen = () => setIsFullscreen(!!(document.fullscreenElement || (document as any).webkitFullscreenElement));

    document.addEventListener("fullscreenchange", toggleFullscreen);
    document.addEventListener("webkitfullscreenchange", toggleFullscreen);

    return () => {
      document.removeEventListener("fullscreenchange", toggleFullscreen);
      document.removeEventListener("webkitfullscreenchange", toggleFullscreen);
    };
  }, [showFullscreenButton]);

  // Filter out slide videos which aren't muted, because prerender would start playing their music in the background
  const slidesToPrerender = (slides as Slide[])
    .filter((slide, i) => slide.type === "slide" && slide.values[`shortVideo${i + 1}`] ? slide.values[`mute${i + 1}`] : true);

  return (
    <Box {...props} position="relative">
      <GlobalImageGalleryStyles /> {/* Apply custom global styling for ImageGallery */}
      <ImageGallery
        ref={galleryRef as unknown as RefObject<any>}
        items={slides as any} // as any because renderItem overwrites original library behaviour, hence library expects wrong type
        renderItem={(item) => isReadyToRender &&
          (slidesToPrerender.includes(item as any) || (item as any).id === slides[currentIndex].id) && // render just current index slide or slides which are to be prerendered
          <ResolveItem item={item as unknown as SlideType} currentIndex={currentIndex} aspectRatio={aspectRatio} width={width} />}
        showPlayButton={false}
        showFullscreenButton={showFullscreenButton || isFullscreen}
        autoPlay={autoPlay ?? false}
        slideInterval={autoPlay && autoPlaySpeed ? autoPlaySpeed : 4500}
        slideDuration={slideDuration}
        showBullets={showBullets || isFullscreen}
        swipingTransitionDuration={550}
        startIndex={startIndex}
        onBeforeSlide={(i) => setCurrentIndex(i)}
        onScreenChange={() => {
          if (isFullscreen) { // When leaving fullscreen return to starting index immediately
            setSlideDuration(0);
            galleryRef.current?.slideToIndex?.(startIndex);
            setSlideDuration(SLIDE_DURATION);
            setRenderCarousel?.(false);
            if (refToScrollIntoAfterFullScreenClose?.current) {
              const offset = 100; // Because of the top menu the target element to scroll into would be cut off from the top. This offset scrolls a bit higher to show the whole element
              window.scrollTo({ top: window.scrollY + refToScrollIntoAfterFullScreenClose.current.getBoundingClientRect().top - offset });
            }
          }
          setIsFullscreen(!isFullscreen);
        }}
        renderLeftNav={(onClick) => (showNavArrows || isFullscreen) && (
          <Icon
            onClick={onClick}
            width="3.2rem"
            svg={<ArrowIcon />}
            fill="#fff"
            transform="rotate(90deg)"
            height="3rem"
            ml="1rem"
            position="absolute"
            top={isFullscreen ? "49%" : "42%"}
            zIndex={1001}
            cursor="pointer"
            _hover={{ transition: "transform 300ms", transform: "rotate(90deg) scale(1.3)" }}
          />
        )}
        renderRightNav={(onClick) => (showNavArrows || isFullscreen) && (
          <Icon
            onClick={onClick}
            width="3.2rem"
            svg={<ArrowIcon />}
            fill="#fff"
            transform="rotate(-90deg)"
            height="3rem"
            mr="1rem"
            position="absolute"
            top={isFullscreen ? "49%" : "42%"}
            right="0%"
            zIndex={1001}
            cursor="pointer"
            _hover={{ transition: "transform 300ms", transform: "rotate(-90deg) scale(1.3)" }}
          />
        )}
      />
    </Box>
  );
};

const ResolveItem: FC<{
  item: SlideType;
  currentIndex: number;
  aspectRatio?: number;
  width: number | null;
}> = memo(({ item, currentIndex, aspectRatio, width }) => {
  const { file, stringify, value } = useLayout();
  if (!width) return null;

  // check whether square slide will be rendered in order to assign correct aspect ratio
  const isSquareSlideToBeRendered = width < XS &&
    (item as Slide).type === "slide" && !!((item as Slide)?.values.img0 || (item as Slide)?.values.shortVideo0);

  const renderItem = () => {
    switch ((item as any).type) { // as any because File does not have property "type", falling into the default block
      case "video":
        return <VideoComponent video={item as Video} key={currentIndex} />;

      case "shortVideo":
        return (
          <ShortVideo
            video={(item as ShortVideoType).values as unknown as ShortVideoValuesRestructured}
            id={item.id}
            key={currentIndex}
          />
        );

      case "slide": // show video if it's present and use corresponding image as thumbnail. If there is no video, show just the image
        const slideItem = item as Slide;

        const restructureSlideToShortVideoType = (slideValues: SlideValues, videoNumber: ShortVideoSizes) => ({
          loop: true, // homepage slide videos are always looped, autoplayed and without controls
          autoPlay: true,
          noControl: true,
          mute: slideValues[`mute${videoNumber}`],
          shortVideo: slideValues[`shortVideo${videoNumber}`],
          videoThumbnail: slideValues[`img${videoNumber}`]
        });

        // choose correct video resolution based on screen width
        if (width < XS && slideItem.values?.shortVideo0) { // expecting 1080x1080 (squared for small screen sizes, highest priority)
          return (
            <ShortVideo
              carouselIndex={currentIndex}
              video={restructureSlideToShortVideoType(slideItem.values, 0) as unknown as ShortVideoValuesRestructured}
              id={slideItem.id}
              newWindow={slideItem.values.btnNewWindow}
              targetURL={stringify(slideItem.values.btnTargetURL)}
              button={slideItem.values.showBtn && <SlideButton stringify={stringify} slideItem={slideItem} />}
              ratio={1}
            />
          );
        }

        if (width < XS && slideItem.values?.shortVideo1) { // expecting 767x340 (fallback for small screen sizes if not squared format)
          return (
            <ShortVideo
              carouselIndex={currentIndex}
              video={restructureSlideToShortVideoType(slideItem.values, 1) as unknown as ShortVideoValuesRestructured}
              id={slideItem.id}
              newWindow={slideItem.values.btnNewWindow}
              targetURL={stringify(slideItem.values.btnTargetURL)}
              button={slideItem.values.showBtn && <SlideButton stringify={stringify} slideItem={slideItem} />}
            />
          );
        }
        if (width >= XS && width < SM && slideItem.values?.shortVideo2) { // expecting 1024x750
          return (
            <ShortVideo
              carouselIndex={currentIndex}
              video={restructureSlideToShortVideoType(slideItem.values, 2) as unknown as ShortVideoValuesRestructured}
              id={slideItem.id}
              newWindow={slideItem.values.btnNewWindow}
              targetURL={stringify(slideItem.values.btnTargetURL)}
              button={slideItem.values.showBtn && <SlideButton stringify={stringify} slideItem={slideItem} />}
            />
          );
        }
        if (width >= SM && width < MD && slideItem.values?.shortVideo3) { // expecting 1440x750
          return (
            <ShortVideo
              carouselIndex={currentIndex}
              video={restructureSlideToShortVideoType(slideItem.values, 3) as unknown as ShortVideoValuesRestructured}
              id={slideItem.id}
              newWindow={slideItem.values.btnNewWindow}
              targetURL={stringify(slideItem.values.btnTargetURL)}
              button={slideItem.values.showBtn && <SlideButton stringify={stringify} slideItem={slideItem} />}
            />
          );
        }
        if (width >= MD && width < LG && slideItem.values?.shortVideo4) { // expecting 1920x750
          return (
            <ShortVideo
              carouselIndex={currentIndex}
              video={restructureSlideToShortVideoType(slideItem.values, 4) as unknown as ShortVideoValuesRestructured}
              id={slideItem.id}
              newWindow={slideItem.values.btnNewWindow}
              targetURL={stringify(slideItem.values.btnTargetURL)}
              button={slideItem.values.showBtn && <SlideButton stringify={stringify} slideItem={slideItem} />}
            />
          );
        }
        if (width >= LG && slideItem.values?.shortVideo5) { // expecting 2560x750
          return (
            <ShortVideo
              carouselIndex={currentIndex}
              video={restructureSlideToShortVideoType(slideItem.values, 5) as unknown as ShortVideoValuesRestructured}
              id={slideItem.id}
              newWindow={slideItem.values.btnNewWindow}
              targetURL={stringify(slideItem.values.btnTargetURL)}
              button={slideItem.values.showBtn && <SlideButton stringify={stringify} slideItem={slideItem} />}
            />
          );
        }

        // if no video is present for given resolution, show hero image instead
        return (
          <HeroImage
            key={slideItem.id}
            width="100%"
            heroImageText={stringify(slideItem.values.heading)}
            heroImageTextAlign={slideItem.values.alignment === "1" ? "left" : slideItem.values.alignment === "2" ? "center" : "right"}
            button={slideItem.values.showBtn && <SlideButton stringify={stringify} slideItem={slideItem} />}
            heroImageBackground1080x1080={file(slideItem.values.img0, { width: 1080, height: 1080 })}
            heroImageBackground767x340={file(slideItem.values.img1, { width: 767, height: 340 })}
            heroImageBackground1024x750={file(slideItem.values.img2, { width: 1024, height: 750 })}
            heroImageBackground1440x750={file(slideItem.values.img3, { width: 1440, height: 750 })}
            heroImageBackground1920x750={file(slideItem.values.img4, { width: 1920, height: 750, withoutEnlargement: true })}
            heroImageBackground2560x750={file(slideItem.values.img5, { width: 2560, height: 750, withoutEnlargement: true })}
            heroImageBackground767x340Retina={file(slideItem.values.img1, { width: 767, height: 340, enlargement: 2 })}
            heroImageBackground1024x750Retina={file(slideItem.values.img2, { width: 1024, height: 750, enlargement: 2 })}
            heroImageBackground1440x750Retina={file(slideItem.values.img3, { width: 1440, height: 750, enlargement: 2 })}
            heroImageBackground1920x750Retina={file(slideItem.values.img4, {
              width: 1920, height: 750, enlargement: 2, withoutEnlargement: true
            })}
            heroImageBackground2560x750Retina={file(slideItem.values.img5, {
              width: 2560, height: 750, enlargement: 2, withoutEnlargement: true
            })}
            targetURL={stringify(slideItem.values.targetURL)}
            newWindow={value(slideItem.values.newWindow)}
          />
        );
      default:
        const imgItem = item as File;
        return (
          <Box width="100%" height="100%">
            <Img src={file(imgItem)} height="100%" key={currentIndex} objectFit="contain" />
          </Box>
        );
    }
  };

  return (
    isSquareSlideToBeRendered ? (
      <AspectRatio ratio={1} background="black">
        {renderItem()}
      </AspectRatio>
    ) : aspectRatio ? (
      <AspectRatio ratio={aspectRatio} background="black">
        {renderItem()}
      </AspectRatio>
    ) : <>{renderItem()}</>
  );
});

export default VideoImageCarousel;

interface SlideButtonProps {
  stringify: StringifyFunc;
  slideItem: Slide;
}

const SlideButton = ({ stringify, slideItem }: SlideButtonProps) => {
  return (
    <Button
      as="a"
      href={stringify(slideItem.values.btnTargetURL)}
      target={slideItem.values.btnNewWindow ? "_blank" : "_self"}
      height={{ _: "3rem", sm: "6rem" }}
    >
      {stringify(slideItem.values.btnText)}
    </Button>
  );
};

// Approach with styled(ImageGallery) does not work, because library's inline styling still takes priority. Global styling is needed to be used to solve the issue
const GlobalImageGalleryStyles = createGlobalStyle`
  @media (max-width: 768px) {
    .image-gallery-bullets {
      bottom: 10px !important;
    }
  }
`;
